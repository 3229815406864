import React from "react";
import gokhan from "../../assets/gokhan.png";
import Typewriter from "typewriter-effect";

const Aboutme = () => {
  return (
    <div id="aboutme">
      <div className="aboutme-container">
        <img
          className="aboutme-container-img"
          src={gokhan}
          alt="gokhan kabar"
        />
        <div className="aboutme-container-text">
          <h1>Bienvenue,</h1>
          <p>
            Je suis <span>Gokhan Kabar</span>
          </p>
          <h2>
            <Typewriter
              options={{
                autoStart: true,
                loop: true,
                delay: 20,
                strings: ["Développeur Web", "Développeur Full Stack"],
              }}
            />
          </h2>
        </div>
      </div>
    </div>
  );
};

export default Aboutme;
