// Home.js
import React, { useEffect, useRef } from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

// Importez vos composants ici
import Aboutme from './components/home/Aboutme';
import Apropos from './components/home/Apropos.jsx';
import Footer from './components/home/Footer.jsx';
import Navbar from './components/home/Navbar';
import Skills from './components/home/Skills';
import TimelineSchool from './components/home/TimelineSchool.jsx';
import { MotionPathPlugin } from "gsap/MotionPathPlugin";

gsap.registerPlugin(MotionPathPlugin);

gsap.registerPlugin(ScrollTrigger);

const Home = () => {
  const ballRefs = useRef([]);
  ballRefs.current = [];

  useEffect(() => {
    const zigzagPath = [
      { x: 50, y: 50 },
      { x: window.innerWidth - 100, y: window.innerHeight / 4 },
      { x: 100, y: window.innerHeight / 2 },
      { x: window.innerWidth - 100, y: window.innerHeight * 3 / 4 },
      { x: window.innerWidth, y: window.innerHeight }
    ];

    ballRefs.current.forEach((ball, index) => {
      let tl = gsap.timeline({
        scrollTrigger: {
          trigger: '.home',
          start: 'top top',
          end: 'bottom bottom',
          scrub: true
        }
      });

      tl.to(ball, {
        motionPath: {
          path: zigzagPath,
          align: zigzagPath,
          autoRotate: true,
          alignOrigin: [0.5, 0.5]
        },
        ease: "none",
        delay: 0.1 * index // Delay each ball slightly
      });
    });
  }, []);

  const numberOfBalls = 5; // For example, create 5 balls

  return (
    <div className='home'>
      {Array.from({ length: numberOfBalls }).map((_, index) => (
        <div 
          key={index}
          className='ball'
          ref={el => ballRefs.current[index] = el} // Assign each ball ref
        />
      ))}      <Navbar />
      <Aboutme />
      <Apropos />
      <TimelineSchool />
      <Skills />
      {/* <Projets /> Commenté car non importé dans l'exemple fourni */}
      <Footer />
    </div>
  );
};

export default Home;
