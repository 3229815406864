import React, { useEffect, useRef } from 'react';
import { MdWorkOutline } from 'react-icons/md';
import { BsFileEarmarkCodeFill, BsCodeSlash } from 'react-icons/bs';
import { createRef } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';

// Assurez-vous d'enregistrer le plugin une seule fois dans votre application, pas dans le composant
gsap.registerPlugin(ScrollTrigger);

function Apropos() {
  // Création des refs pour les éléments à animer
  const iconRefs = useRef([]);
  const containerRefs = useRef([]);
  iconRefs.current = new Array(3).fill().map((_, i) => iconRefs.current[i] ?? createRef());
  containerRefs.current = new Array(3).fill().map((_, i) => containerRefs.current[i] ?? createRef());

  const slideIn = (elem, direction = 'left', delay = 0, duration = 1) => {
    gsap.fromTo(
      elem,
      {
        autoAlpha: 0,
        x: direction === 'left' ? -200 : 0,
        y: direction === 'top' ? -200 : 0,
      },
      {
        autoAlpha: 1,
        x: 0,
        y: 0,
        delay,
        duration,
        scrollTrigger: {
          trigger: elem,
          start: direction === 'left' ? 'bottom center' : 'top top+=100',
          end: '+=100',
          scrub: 1,

        },
      }
    );
  };

  useEffect(() => {
    iconRefs.current.forEach(ref => {
      slideIn(ref.current, 'left');
    });
    containerRefs.current.forEach(ref => {
      slideIn(ref.current, 'top', 0, 2);
    });
  }, []);

  return (
    <div className="apropos">
      <div className="apropos-container-icons">
        <ul>
          <li ref={iconRefs.current[0]} className="apropos-container-icons-1">
            <BsCodeSlash />
            <h2>05+</h2>
            <h3>Années d'expérience</h3>
          </li>
          <li ref={iconRefs.current[1]} className="apropos-container-icons-2">
            <BsFileEarmarkCodeFill />
            <h2>15+</h2>
            <h3>Projets créés</h3>
          </li>
          <li ref={iconRefs.current[2]} className="apropos-container-icons-3">
            <MdWorkOutline />
            <h2>01+</h2>
            <h3>Expérience en entreprise</h3>
          </li>
        </ul>
      </div>
      <div className="apropos-container">
        <div ref={containerRefs.current[0]} className="apropos-container-1">
          <p>
            Je suis un <b>Développeur Web Junior</b> au profil polyvalent avec des compétences <b>front-end</b> ainsi que des compétences <b>back-end</b>.
          </p>
        </div>
        <div ref={containerRefs.current[1]} className="apropos-container-2">
          <p>
            Actuellement à <b>l'École Supérieure de Génie Informatique</b> pour obtenir un <b>bac +5</b>.
          </p>
          <p>
            Je suis en contrat d'alternance chez <b>Ippon Technologies</b> en tant que <b>Développeur Web</b>.
          </p>
        </div>
        <div ref={containerRefs.current[2]} className="apropos-container-3">
          <p>
            Passionnée par l'informatique depuis mon jeune âge, durant mes années d'études j'ai appris à travailler en <b>équipe</b> et à être <b>autonome</b>.
          </p>
          <p>
            Mon objectif est d'améliorer constamment mes compétences en développement web avec de nouvelles technologies.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Apropos;
