import React, { useEffect, useRef, useCallback } from "react";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { TbSchool } from "react-icons/tb";
import { MdWorkOutline } from "react-icons/md";


function TimelineSchool() {
  const itemsRef = useRef([]);
  gsap.registerPlugin(ScrollTrigger);

  const addToRefs = useCallback((el) => {
    if (el && !itemsRef.current.includes(el)) {
      itemsRef.current.push(el);
    }
  }, []);

  const education = [
    {
      date: "Octobre 2023 - Octobre 2025",
      ecole: "ESGI",
      diplome: "Ingénierie du web",
      lieu: ", Paris, France",
      icon: "work",
    },
    {
      date: "Février 2023 - Octobre 2023",
      ecole: "Ippon Technologies",
      diplome: "Développeur Web",
      lieu: ", Paris, France",
      icon: "work",
    },
    {
      date: "Octobre 2022 - Novembre 2022",
      ecole: "Blade",
      diplome: "Développeur Web Full Stack",
      lieu: ", Villejuif, France",
      icon: "work",
      desc: [
        " (ReactJS,NestJS,MySQL)",
        "- Développement du front-end en ReactJS",
        "- Développement de composant Front en liaison avec un Back End en NestJS",
      ],
    },
    {
      date: "Octobre 2022 - Juillet 2023",
      ecole: "Digital School Of Paris",
      diplome: "Mastère Architecte Web",
      lieu: ", Vincennes, France",
      icon: "school",
    },
    {
      date: "Septembre 2019 - Juin 2022",
      ecole: "Université Paris 8",
      diplome: "Licence Informatique",
      lieu: ", Saint-denis, France",
      icon: "school",
    },
    {
      date: "Septembre 2016 - Juillet 2019",
      ecole: "Lycée Henri Wallon",
      diplome: "Baccalauréat Scientifique Spécialité ISN",
      lieu: ", Aubervilliers, France",
      icon: "school",
    },
  ];

  useEffect(() => {
    itemsRef.current.forEach((item) => {
      gsap.timeline({
        scrollTrigger: {
          trigger: item,
          start: "center center",
          end: "bottom 25%",
          scrub: 1,
        },
      })
      .fromTo(
        item,
        {
          autoAlpha: 0,
          clipPath: "polygon(0 0, 0% 0, 0% 100%, 0 100%)",
        },
        {
          duration: 1,
          autoAlpha: 1,
          ease: "power2",
          clipPath: "polygon(0 0, 100% 0, 100% 100%, 0 100%)",
        }
      )
      .fromTo(
        item.querySelectorAll(".timeline_content"),
        { x: 100, autoAlpha: 0 },
        {
          x: 0,
          autoAlpha: 1,
          duration: 1,
          stagger: 0.3,
        }
      );
    });

    // Nettoyage de l'effet pour les instances de ScrollTrigger
    return () => {
      ScrollTrigger.getAll().forEach((trigger) => trigger.kill());
    };
  }, [addToRefs]);

  return (
    <div className="timeline-section">
      <div className="timeline_title">
        <h2>
          <span>01.</span>Expérience & Diplômes
        </h2>
      </div>

      <div className="timeline-items">
        {education.map((item, index) => {
          let isSchoolIcon = item.icon === "school";
          return (
            <div className="timeline-item" key={index} ref={addToRefs}>
              <div className="timeline-dot">
                {isSchoolIcon ? <TbSchool /> : <MdWorkOutline />}
              </div>
              <div className="timeline-date">{item.date}</div>
              <div className="timeline-content">
                <h2>{item.diplome}</h2>
                <div className="city">
                  <h3>{item.ecole}</h3>
                  <h4>{item.lieu}</h4>
                </div>
                {item.desc && (
                  <ul>
                    {item.desc.map((descItem, i) => (
                      <li key={i}>{descItem}</li>
                    ))}
                  </ul>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default TimelineSchool;