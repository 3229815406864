import React from "react";
import { BsGithub } from "react-icons/bs";
import { HiMail } from "react-icons/hi";
import { BsLinkedin } from "react-icons/bs";

function Footer() {
  return (
    <div className="footer">
      <ul>
        <li>
          <a href="https://github.com/GokhanKabar">
            <BsGithub />
          </a>
        </li>
        <li>
          <HiMail />
          gokhankabar@hotmail.fr
        </li>
        <li>
          <a href="https://www.linkedin.com/in/gokhan-kabar/">
            <BsLinkedin />
          </a>
        </li>
      </ul>
    </div>
  );
}

export default Footer;
