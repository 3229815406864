import React from "react";
import { ReactComponent as Github } from "../../assets/GitHub.svg";
import { ReactComponent as Linkedin } from "../../assets/Linkedin.svg";

const Navbar = () => {
  return (
    <div className="navbar">
      <a href="https://github.com/GokhanKabar" target="_blank">
        <Github />
      </a>
      <a href="https://www.linkedin.com/in/gokhan-kabar/" target="_blank">
        <Linkedin />
      </a>
    </div>
  );
};

export default Navbar;
